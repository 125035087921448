import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Department from './Department';

function App() {
  const [myData, setMyData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const me = () => {
    axios.get("/me")
    .then(response => {
      setMyData(response.data);
    })
    .catch(err => {
      console.log("woah error");
    })
  };

  const org = () => {
    axios.get("/organization-chart")
    .then(response => {
      setOrgData(response.data);
    })
    .catch(err => {
      console.log("woah error 2");
    })
  };

  useEffect(() => {
    me();
    org();
  }, []);

  const aboutMe = (data) => {
    return (<>
      My name is {data.name}, learn more about my professional work <a href={data.homepage}>
        here.
      </a> <br/>
      I am a full time student at Columbia, you can check out my past projects <a href={data.githubURL}> here </a>
    </>);
  };

  const displayOrg = (data) => {
    return (<>
    Organization: <br/>
      {data.organization != null
        ? data.organization.departments.map(department => {
            return <Department department={department} />;
          })
        : null}
    </>);
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>About me!</h1>
        <p>
          {myData != null ? aboutMe(myData) : null}
        </p>
        <br></br>
        <h1>Some Cloudflare Data!</h1>
        <p>
          {orgData != null ? displayOrg(orgData) : null}
        </p>
      </header>
    </div>
  );
}

export default App;
