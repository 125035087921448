import React from "react";

export default function Department(props) {
    const department = props.department;
    return (
        <>
            <h2>Department: {department.name}</h2>
            <h3>Manager: {department.managerName}</h3>
            {department.employees.map((employee) => {
                return (<><p> Employee Name: {employee.name}</p>
                <br/>
                <p>Office: {employee.office}</p>
                <br/>
                <p>Top Skill: {employee.skill1}</p>
                <br/>
                <p>------------------------------</p></>)
            })}
        </>
    );
}